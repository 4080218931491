import React, { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom'

const Header = () => {

    const [show, setShow] = useState(false);

    return (
        <>
            <div className="header">
                <nav className="nav">
                    <div className="logo">
                        <img src="../assets/logo.jpg" alt="logo" />
                    </div>
                    <ul className={!show ? "menu" : "show-menu menu"}>
                        <li><Link to={"/"} onClick={() => setShow(false)}>Home</Link></li>
                        <li><Link to={"/about"} onClick={() => setShow(false)}>About</Link></li>
                    </ul>
                    <div className="hamburger">
                        <GiHamburgerMenu onClick={() => setShow(!show)} />
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header
