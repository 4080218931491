import React from 'react'
import MainBanner from './MainBanner'
import Codes from './Codes'

const Home = () => {
    const desc01 = "On the PC, you have two options: you can enter a code directly on your mobile phone while you're playing, or via the console. To open the console, press '²' at the top left of your keyboard before typing the following messages."
    const desc02 = "If you wish to enter the codes using the telephone (in-game), press the number pad key, then space, enter the numbers that follow, and press space again."
    const desc03 = "On consoles, the GTA cheat has been a huge classic for many years now. To activate a code, all you have to do is chain together a combination of keys (but you can also enter the codes into your phone)."
    const desc04 = ""

    //01
    const gamePlayCode01 = [
        'PAINKILLER - Makes you immortal',
        'TURTLE - Recharges health and armour',
        'FUGITIVE - Increases search level',
        'LAWYERUP - Reduces search level',
        'POWERUP - Recharges special ability',
        'LIQUOR - Drunk mode',
        'SLOWMO - Slow motion mode',
        'DEADEYE - Slow - motion aiming',
        'CATCHME - Fast running',
        'GOTGILLS - Fast swim',
        'HOPTOIT - Super jump',
        'SKYDIVE - Grants a parachute',
        'SKYFALL - Glides through the sky',
        'FLOATER - Changes gravity',
        'MAKEITRAIN - Changes the weather',
        'SNOWDAY - Skidding vehicles'
    ]
    const vehicleCodes01 = [
        'BANDIT - BMX bike',
        'BARNSTORM - Aerobatic plane',
        'BUZZOFF - Buzzard attack helicopter',
        'COMET - Sports car',
        'FLYSPRAY - Duster aeroplane',
        'OFFROAD - Sanchez motorbike',
        'RAPIDGT - Rapid GT car',
        'ROCKET - PCJ-600 motorbike',
        'TRASHED - Refuse truck',
        'VINEWOOD - Limousine',
        "DEATHCAR - Duke O'Death car (after the Duel challenge)",
        'EXTINCT - Seaplane (after the Seaplane challenge)',
        'BUBBLES - Kraken Submarine (after the Wildlife Photography challenge)'
    ]
    const weaponRelatedCodes01 = [
        'HOTHANDS - Explosive punches',
        'HIGHEX - Explosive ammunition',
        'FIRE - Incendiary bullets',
        'TOOLUP - Gives you all the weapons in the game'
    ]

    //02
    const gamePlayCode02 = [
        '1-999-724-6545537 - Makes you immortal',
        '1-999-887-853 - Recharges health and armour',
        '1-999-384-48483 - Increase research level',
        '1-999-529-93787 - Reduces search level',
        '1-999-769-3787 - Recharges special ability',
        '1-999-547867 - Drunk mode',
        '1-999-332-3393 - Slow mode',
        '1-999-332-3393 - Slow motion aiming',
        '1-999-228-2463 - Fast run',
        '1-999-46844557 - Fast swim',
        '1-999-467-8648 - Super jump',
        '1-999-759-3483 - Grant a parachute',
        '1-999-759-3255 - Glide through the sky',
        '1-999-356-2837 - Changes gravity',
        '1-999-625-348-7246 - Change the weather',
        '1-999-766-9329 - Skidding vehicles',
    ]
    const vehicleCodes02 = [
        '1-999-226-348 - BMX bike',
        '1-999-227-678-676 - Aerobatic plane',
        '1-999-289-9633 - Buzzard attack helicopter',
        '1-999-266-38 - Sports car',
        '1-999-359-77729 - Duster aeroplane',
        '1-999-4653-461 - Golf cart',
        '1-999-633-7623 - Sanchez motorbike',
        '1-999-727-4348 - Rapid GT car',
        '1-999-762-538 - PCJ-600 Motorcycle',
        '1-999-872-7433 - Garbage truck',
        '1-999-846-39663 - Limousine',
        "1-999-332-84227 - Duke O'Death car (after the Duel challenge)",
        '1-999-398-4628 - Seaplane (after the Seaplane challenge)',
        '1-999-282-2537 - Kraken Submarine (after the Wildlife Photography challenge)',
    ]
    const weaponRelatedCodes02 = [
        '1-999-468-42637 - Explosive Punches',
        '1-999-444-439 - Explosive ammunition',
        '1-999-4623-634279 - Incendiary bullets',
        '1-999-866-587 - Grants all weapons in the game',
    ]
    //03
    const gamePlayCode03 = [
        'Make immortal - Right, X, Right, Left, Right, R1, Right, Left, X, Triangle',
        'Replenishes health and armour - Circle, L1, Triangle, R2, X, Square, Circle, Right, Square, L1, L1, L1, L1',
        'Increases the search level - R1, R1, Circle, R2, Left, Right, Left, Right, Left, Right, Left, Right',
        'Reduces the search level - R1, R1, Circle, R2, Right, Left, Right, Left, Right, Left, Right, Left',
        'Recharges special ability - X, X, Square, R1, L1, X, Right, Left, X',
        'Sober mode - Triangle, Right, Right, Right, Left, Right, Square, Circle, Left',
        'Slow-motion mode - Triangle, Left, Left, Right, Right, Square, R2, R1',
        'Slow Aim - Square, L2, R1, Triangle, Left, Square, L2, Right, X',
        'Fast run - Triangle, Left, Left, Right, Right, L2, L1, Square',
        'Fast swim - Left, Left, L1, Right, Right, Right, R2, Left, L2, Right, L2, L2, Super jump - Square, Circle, Circle, L2, Square, Square, Left, Right, X',
        'Grants a parachute - Left, Right, L1, L2, R1, R2, R2, Left, Left, Left, Right, L1',
        'Allows you to glide through the sky - L1, L2, R1, R2, Left, Right, Left, Right, L1, L2, R1, R2, Left, Right, Left, Right',
        'Change gravity - Left, Left, L1, R1, L1, Right, Left, L1, Left',
        'Change weather - R2, X, L1, L1, L2, L2, L2, L2, Square',
        'Vehicle skid - Triangle, R1, R1, Left, R1, L1, R2, L1',
    ]
    const vehicleCodes03 = [
        'BMX bike - Left, Left, Right, Right, Right, Left, Right, Square, Circle, Triangle, R1, R2',
        'Aerobatic aircraft - Round, Right, L1, L2, Left, R1, L1, L1, Left, Left, Cross, Triangle',
        'Buzzard Attack Helicopter - Circle, Circle, L1, Circle, Circle, Circle, L1, L2, R1, Triangle, Circle, Triangle',
        'Sports car - R1, Circle, R2, Right, L1, L2, X, X, Square, R1',
        'Duster Plane - Right, Left, R1, R1, Left, Triangle, Triangle, X, Circle, L1',
        'Golf cart - Circle, L1, Left, R1, L2, X, R1, L1, Circle, X',
        'Moto Sanchez - Left, Left, Right, Right, Left, Right, Square, Circle, Triangle, R1, R2.',
        'Rapid GT Car - R2, L1, Circle, Right, L1, R1, Right, Left, Circle, R2',
        'Motorbike PCJ-600 - R1, Right, Left, R2, Left, Right, Square, L2, L1',
        'Refuse truck - Circle, R1, Circle, R1, R1, Left, Left, R1, L1, Circle, Right',
        'Limousine - R2, Right, L2, Left, Left, Left, R1, L1, Circle, Right',
    ]
    const weaponRelatedCodes03 = [
        'Explosive punches - Right, Left, X, Triangle, R1, Circle, Circle, Circle, Circle, L2',
        'Explosive ammunition - Right, Square, X, Left, R1, R2, Left, Right, Right, Right, L1, L1, L1, L1',
        'Incendiary bullets - L1, R1, Square, R1, Left, R2, R1, Left, Square, Right, L1, L1',
        'Grants all weapons in the game - Triangle, R2, Left, L1, X, Right, Triangle, Down, Square, L1, L1, L1, L1',
    ]

    //04
    const gamePlayCode04 = [
        'Make immortal - Right, A, Right, Left, Right, RB, Right, Left, A, Y',
        'Recharges health and armour - B, LB, Y, RT, A, X, B, Right, X, LB, LB, LB, LB',
        'Increases search level - RB, RB, B, RT, Left, Right, Left, Left, Right, Left, Right',
        'Reduces the search level - RB, RB, B, RT, Right, Left, Right, Left, Right, Left, Right, Left',
        'Recharges special ability - A, A, X, RB, LB, A, Right, Left, A',
        'Drunk mode - Y, Right, Right, Left, Right, X, B, Left',
        'Slow-motion mode - Y, Left, Left, Right, Right, X, RT, RB',
        'Slow-motion aiming - X, LT, RB, Y, Left, X, LT, Right, A',
        'Fast travel - Y, Left, Left, Right, Right, LT, LB, X',
        'Fast swim - Left, Left, LB, Right, Right, RT, Left, LT, Right',
        'Super jump - LT, LT, X, B, B, LT, X, X, X, Left, Right, A',
        'Grants a parachute - Left, Right, LB, LT, RB, RT, RT, Left, Left, Right, LB',
        'Allows you to soar through the sky - LB, LT, RB, RT, Left, Right, Left, Right, LB, LT, RB, RT, Left, Right, Left, Right',
        'Change gravity - Left, Left, LB, RB, LB, Right, Left, LB, Left',
        'Change weather - RT, A, LB, LB, LB, LT, LT, LT, X',
        'Vehicle skid - Y, RB, RB, Left, RB, LB, RT, LB',
    ]
    const vehicleCodes04 = [
        'BMX bike - Left, Left, Right, Right, Right, Left, Right, X, B, Y, RB, RT',
        'Aerobatic aircraft - B, Right, LB, LT, LT, Left, RB, LB, LB, Left, Left, Left, A, Y',
        'Sports car - RB, B, RT, Right, LB, LT, A, A, X, RB',
        'Duster Plane - Right, Left, RB, RB, Left, Y, Y, A, B, LB, LB',
        'Golf cart - B, LB, Left, RB, LT, A, RB, LB, B, A',
        'Moto Sanchez - Left, Left, Right, Right, Left, Right, X, B, Y, RB, RT',
        'Rapid GT Car - RT, LB, B, Right, LB, RB, Right, Left, B, RT',
        'Motorbike PCJ-600 - RB, Right, Left, Right, RT, Left, Right, X, Right, LT, LB, LB',
        'Refuse truck - B, RB, B, RB, RB, Left, Left, RB, LB, B, Right',
        'Limousine - RT, Right, LT, Left, Left, Left, RB, LB, B, Right',
    ]
    const weaponRelatedCodes04 = [
        'Explosive punches - Right, Left, A, Y, RB, B, B, B, B, LT',
        'Explosive ammunition - Right, X, A, Left, RB, RT, Left, Right, Right, LB, LB, LB',
        'Incendiary bullets - LB, RB, X, RB, Left, RT, RB, Left, X, Right, LB, LB',
        'Grants all the weapons in the game - Y, RT, Left, LB, A, Right, Y, Low, X, LB, LB, LB, LB',
    ]
    return (
        <>
            <MainBanner />
            <Codes number={"01"} title={"GTA V - Codes on PC"} desc={desc01} game={gamePlayCode01} vehi={vehicleCodes01} weap={weaponRelatedCodes01} />
            <Codes number={"02"} title={"GTA V - Codes by phone"} desc={desc02} game={gamePlayCode02} vehi={vehicleCodes02} weap={weaponRelatedCodes02} />
            <Codes number={"03"} title={"GTA V - PS5 codes "} desc={desc03} game={gamePlayCode03} vehi={vehicleCodes03} weap={weaponRelatedCodes03} />
            <Codes number={"04"} title={"GTA V - Xbox codes "} desc={desc04} game={gamePlayCode04} vehi={vehicleCodes04} weap={weaponRelatedCodes04} />
        </>
    )
}

export default Home
