import React from 'react'

const Codes = ({ number, title, desc, game, vehi, weap }) => {

  return (
    <>
      <div className='codeBlock'>
        <div className="title">
          <h5>{number}</h5>
          <h2>{title}</h2>
        </div>

        <div className="content">
          <p className='para'>
            <span>
              {desc}
            </span>
          </p>
          <div className="codes">
            <span>Gameplay codes</span>
            <div className="code">
              {
                game && game.map((elm, index) => {
                  return (
                    <Card elm={elm} key={index} />
                  )
                })
              }
            </div>

            <span>Vehicle codes</span>
            <div className="code">
              {
                vehi && vehi.map((elm, index) => {
                  return (
                    <Card elm={elm} key={index} />
                  )
                })
              }
            </div>

            <span>Weapon-related codes</span>
            <div className="code">
              {
                weap && weap.map((elm, index) => {
                  return (
                    <Card elm={elm} key={index} />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Codes

const Card = ({ elm }) => {
  return (
    <p className='card'>{elm}</p>
  )
}