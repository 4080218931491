import React from 'react'

const MainBanner = () => {
    return (
        <>
            <div className="banner-main">
                <div className="image">
                    <img src="assets/bannerimg.jpg" alt="banner" />
                </div>
            </div>
        </>
    )
}

export default MainBanner
