import React from 'react'

const About = () => {
  return (
    <>
      <h5>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas maiores sit totam delectus earum vitae corrupti quam, similique, quod consequatur iusto voluptatem libero accusamus sed quo doloremque minus temporibus aliquam.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet, blanditiis assumenda doloribus vero quasi temporibus natus quam modi ipsa, explicabo ea nisi dicta dolor tempore, vitae et alias dolorum magni.
          Facere ipsa explicabo corrupti possimus recusandae cum voluptates esse, facilis beatae unde culpa ullam placeat incidunt. Dicta fugiat omnis voluptatum cupiditate pariatur expedita, placeat iure aspernatur, est sit animi blanditiis?
          Quas beatae sapiente autem. Tempora animi excepturi ipsum, adipisci consequuntur illo ducimus. Praesentium illo earum odio incidunt magnam consequatur ratione minus nulla, laboriosam culpa? Corrupti dolore obcaecati nesciunt explicabo quaerat!
          Id dolorem ad doloremque earum, rem dolores nemo? Id, reprehenderit repellendus nulla expedita nesciunt repudiandae neque at. Ducimus ullam illo quidem. Cum, beatae officiis natus quos repellat reprehenderit doloremque temporibus.
          Impedit ipsa reiciendis voluptate possimus inventore. Adipisci quisquam, ipsa esse natus omnis animi obcaecati labore, ipsum officiis accusamus voluptatibus modi expedita rem mollitia, ducimus autem incidunt aliquam nostrum ea cumque?
        </p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum voluptas assumenda impedit nemo mollitia omnis accusamus quod incidunt error doloremque modi, laboriosam commodi optio accusantium saepe obcaecati labore exercitationem ipsam?
        Autem fugit voluptates unde asperiores ullam enim nemo dolorem, amet nostrum inventore sequi, totam porro hic optio temporibus sint iusto, sunt corporis voluptatibus facere quas veritatis officiis. Porro, cupiditate alias.
        Deserunt debitis sit placeat. Mollitia beatae omnis saepe quia temporibus quas, nam unde qui nesciunt aspernatur fugit pariatur cupiditate dignissimos, laborum eius cumque molestias consequuntur maxime assumenda voluptate vero consectetur?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam rerum quae perferendis nam animi laudantium voluptatibus praesentium obcaecati mollitia sint, sit sed, possimus voluptates minus ipsum nobis eligendi deleniti quibusdam?
          Error odit accusamus fuga inventore, in molestiae exercitationem reprehenderit fugit voluptatum quia ad voluptas, officiis qui, at quasi et incidunt placeat voluptate vel? In officia necessitatibus assumenda expedita, hic distinctio?
          Rerum voluptates voluptatem odio sunt porro enim tempore eius, aliquid sapiente facere cumque laborum dolor consectetur eligendi suscipit commodi nemo id, possimus animi ex adipisci, doloribus cum quos similique! Harum!
          Provident rem odit tenetur officia illo quisquam assumenda, distinctio quibusdam sint corrupti, eligendi sit ducimus. Architecto excepturi ipsam quis veritatis vel in, odio unde voluptas itaque! Nihil, veniam sequi. Sint.
          Eligendi esse accusamus inventore nihil nesciunt in nulla eos ullam, tempora numquam modi eveniet quia, asperiores officiis, porro minima ut quos maiores voluptatibus. Hic est, quae in ipsa provident incidunt.
          Quae facere esse impedit quam voluptates reprehenderit tenetur? Error exercitationem alias magnam sit excepturi labore. Maxime consectetur labore soluta eius suscipit. Repudiandae consequuntur cum dignissimos maiores dolor accusamus natus quasi.
          Nostrum doloremque maiores temporibus amet! Reprehenderit, eligendi eum facere ea aut similique veniam odit magni neque sit voluptatum quod reiciendis unde suscipit placeat rerum esse sunt eveniet. Quisquam, omnis explicabo!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste totam accusantium incidunt assumenda fugiat unde? Accusantium ducimus excepturi ipsam quo vitae odit, quia reiciendis a esse eligendi illo rerum neque?
          Unde provident accusantium magni nihil, temporibus tenetur? Accusamus amet magnam eos. Ipsam quis et illo. Animi deserunt laudantium possimus assumenda quis iste quos non debitis earum. Repudiandae minus fuga provident.
          Nesciunt autem ex ad ipsum architecto incidunt eaque cum error, expedita magni tenetur praesentium sed consectetur quaerat labore. Voluptate possimus explicabo officia blanditiis quo animi omnis, voluptatem tempora quae? Dignissimos.
          Rem cumque aliquam quam beatae quibusdam laudantium minus autem cum, tempora, velit iusto suscipit sunt facere in dolores. Culpa necessitatibus minima rerum voluptate, eveniet saepe delectus quasi molestiae laboriosam perferendis?
          Dolorum, nulla voluptate. Ipsa quo aperiam ullam quisquam laboriosam, blanditiis minima itaque tempora sint distinctio, officiis magni. Exercitationem labore aut, facilis harum rem consectetur consequuntur, quasi voluptatum nulla delectus repudiandae!
          Et culpa, nostrum dolores quaerat deleniti impedit cupiditate. Aut dignissimos nulla illum doloribus natus, quia repellat quidem exercitationem iste hic nemo laborum doloremque atque earum? Iure, voluptate? Ut, itaque necessitatibus.
          Quasi impedit doloremque corrupti accusamus aut nesciunt qui, sequi eaque, obcaecati culpa ratione minima omnis architecto deleniti totam dolor quod asperiores quas unde nobis non ipsa ab possimus vero. Praesentium.
          Aut rerum suscipit temporibus quo, ipsum officia animi commodi incidunt nobis saepe optio ipsa sed dignissimos sapiente molestiae amet, corporis consequuntur earum. Magni sit dolores, similique ipsum officia odio! Ad?
          Voluptas consequuntur odit dignissimos illum unde recusandae adipisci debitis rem illo ratione ab quidem eius est necessitatibus incidunt cum, ad aut error culpa nostrum voluptatibus suscipit. Recusandae debitis voluptatibus similique!
          Asperiores deserunt eius quae sint quaerat perspiciatis tenetur tempore officia laboriosam minus odio velit ipsum repellat sapiente quasi, aliquid saepe corrupti autem dolorum et. Et eligendi odio quo reprehenderit. Alias!
        </p>
      </h5>
    </>
  )
}

export default About
